const TEST = "test";
const TEST_TEMP = "test-temp";
const STAGE = "stage";
const STAGE_TEMP = "stage-temp";
const PRODUCTION = "production";
const DEVELOPMENT = "development";

const TEST_URL = "https://u-test.cisco.com";
const TEST_TEMP_URL = "https://u-test-temp.cisco.com";
const STAGE_URL = "https://u-stg.cisco.com";
const STAGE_TEMP_URL = "https://u-stg-temp.cisco.com";
const PROD_URL = "https://u.cisco.com";

function environmentFromOrigin(): string {
  const origin = window.location.origin;
  if (origin === TEST_URL) {
    return TEST;
  } else if (origin === TEST_TEMP_URL) {
    return TEST_TEMP;
  } else if (origin === STAGE_URL) {
    return STAGE;
  } else if (origin === STAGE_TEMP_URL) {
    return STAGE_TEMP;
  } else if (origin === PROD_URL) {
    return PRODUCTION;
  }
  return DEVELOPMENT;
}

// setupConfig imports the config file based on the environment and sets the window.env.<key> = value if assignEnv is true.
async function setupConfig(environment: string, assignEnv: boolean = false) {
  let config = {};
  const validEnvironments = [
    DEVELOPMENT,
    PRODUCTION,
    TEST,
    TEST_TEMP,
    "testing",
    STAGE,
    STAGE_TEMP,
  ];
  if (!validEnvironments.includes(environment)) {
    return config;
  }

  // import the config file based on the environment
  switch (environment) {
    case DEVELOPMENT:
      config = await import("../configs/dev.json");
      break;
    case TEST:
      config = await import("../configs/test.json");
      break;
    case STAGE:
      config = await import("../configs/stage.json");
      break;
    case PRODUCTION:
      config = await import("../configs/prod.json");
      break;
    case "testing":
      config = await import("../configs/testing.json");
      break;
  }

  if (assignEnv) {
    assignWindowEnv(config);
  }

  return config;
}

// iterate over config object and set window.env.<key> = value
function assignWindowEnv(config: { [key: string]: any }) {
  if (window && window?.env === undefined) {
    // @ts-ignore
    window.env = {};
  }
  Object.keys(config).forEach((key) => {
    if (window.env[key as keyof typeof window.env] === undefined) {
      // @ts-ignore
      window.env[key as keyof typeof window.env] =
        config[key as keyof typeof config];
    }
  });
}

export { assignWindowEnv, environmentFromOrigin, setupConfig };
