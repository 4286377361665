import type { AxiosInstance } from "axios";
import axios from "axios";
import dayjs from "dayjs";
import * as jose from "jose";
import { useCookies } from "vue3-cookies";
const cookies = useCookies().cookies;
import { EventBus } from "@/store/eventBus";
import { validUrl } from "@/utils/validateUrl";

const validPrefixes = ["retrievePaths", "courses", "certifications"];

function checkTokenExpires(apiToken: string) {
  if (apiToken && apiToken == "null") return false;
  const decodedJwt = jose.decodeJwt(apiToken);
  const expiresAt = dayjs.unix(decodedJwt.exp as number);
  const currentTime = dayjs(dayjs(new Date()));

  if (currentTime.isSame(expiresAt) || currentTime.isAfter(expiresAt)) {
    localStorage.clear();
    if (!import.meta.env.TEST && !import.meta.env.DEV) {
      window.location.reload();
    }
    if (import.meta.env.DEV) {
      EventBus.emit("showBanner", {
        type: "ERROR",
        message: "update your tokens",
      });
    }
  }
  return true;
}

export function localStoreOrCookie(target: string) {
  // we have a version in localStorage, so use it
  if (
    localStorage[target as keyof typeof localStorage] &&
    localStorage[target as keyof typeof localStorage] != "null" &&
    localStorage[target as keyof typeof localStorage] != "undefined"
  ) {
    return localStorage[target as keyof typeof localStorage] as string;
  }
  // that failed, grab it from the cookie, set it in local storage, run away
  const cookieVal = cookies.get(target);
  if (cookieVal && cookieVal != "null") {
    localStorage[target as keyof typeof localStorage] = cookieVal;
    return cookieVal;
  }
  return false;
}

function setupHeaders(instance: AxiosInstance, apiToken: string) {
  instance.defaults.headers.common["Authorization"] = "Bearer " + apiToken;
  // eslint-disable-next-line sonarjs/no-duplicate-string
  instance.defaults.headers.common["x-lacp-secure"] = apiToken;
  // eslint-disable-next-line sonarjs/no-duplicate-string
  instance.defaults.headers.common["x-lacp-profile"] =
    localStoreOrCookie("x-lacp-profile");
  instance.defaults.headers.common["session-id"] =
    localStoreOrCookie("session-id");
  instance.defaults.headers.common["applicationId"] = window.env.APPLICATION_ID;
  return instance;
}

/**
 * Wrapper around our XHR/ajax calls
 */
export default function http() {
  let instance = axios.create({
    headers: {
      Accept: "application/json",
    },
  });

  const apiToken = localStoreOrCookie("x-lacp-secure");

  if (apiToken && checkTokenExpires(apiToken)) {
    instance = setupHeaders(instance, apiToken);
  }

  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      return response;
    },
    function (error) {
      if (error?.code == "ECONNABORTED") {
        return Promise.resolve().catch(() => {
          // Ignore errors
        });
      }
      const isExceptionUrl =
        error?.request?.responseURL?.includes(
          "/clds-proxy/api/commerce/basket/"
        ) ||
        error?.request?.responseURL?.includes("content-validation/api/videos/");
      if (
        error?.response?.status == 404 &&
        !isExceptionUrl &&
        !validUrl(error?.config?.url, validPrefixes)
      ) {
        EventBus.emit("showBanner", {
          type: "ERROR",
          message: error?.response?.data?.error?.error ?? "An error occurred",
        });
      }

      if (error?.response?.status == 403) {
        window.localStorage.clear();

        // redirect to login page
        if (window.env.INTERCEPT_403_REDIRECT) {
          // drop cookies
          // eslint-disable-next-line sonarjs/no-duplicate-string
          cookies.remove("x-lacp-secure");
          // eslint-disable-next-line sonarjs/no-duplicate-string
          cookies.remove("x-lacp-profile");

          // redirect to login
          window.location.href =
            window.location.origin +
            "/login?returnUrl=" +
            window.location.pathname;
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
}
